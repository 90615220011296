import { Text } from 'components';
import React from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import HomePageIcon from 'assets/images/starter/2.1.png';
import HomePageIcon2 from 'assets/images/starter/2.2.png';
import FactorIcon from 'assets/images/starter/3.1.png';
import FactorIcon2 from 'assets/images/starter/3.2.png';
import SymptomsIcon from 'assets/images/starter/4.1.png';
import SymptomsIcon2 from 'assets/images/starter/4.2.png';
import SymptomsIcon3 from 'assets/images/starter/4.3.png';
import SymptomsIcon4 from 'assets/images/starter/4.4.png';
import AnalyzeIcon from 'assets/images/starter/5.1.png';
import AnalyzeIcon2 from 'assets/images/starter/5.2.png';
import AnalyzeIcon6 from 'assets/images/starter/6.1.png';
import Layout from 'layouts/Layout';

const Single = () => (
  <Layout>
    <Container>
      <Title>Getting to know Mindway | Tips for a smooth start</Title>
      <StyledText>
        We understand that starting something new can be overwhelming but
        don&apos;t worry. Here are several tips for a simple and effective start
        with Mindway, which was just rated as the No. 1 chronic health
        conditions tracker and management app in a systematic review of a
        peer-reviewed scientific journal.
      </StyledText>
      <StyledText>
        We all know that adopting new habits takes time. Start slowly! While you
        can track anything with Mindway, we highly recommend tracking only a few
        things in the beginning. Choose only the most important factors and
        stick to them. Seeing the benefits of tracking a few factors will give
        you the motivation to build new habits and your new healthier routine!
      </StyledText>
      <StyledText>
        All of the widgets let you make multiple logs at different times of the
        day or you can make all your logs at once. And remember, the more data
        you enter, the better and more accurate insights you will get!
      </StyledText>
      <Heading>1. Welcome! Set up your homepage</Heading>
      <StyledText>
        You can track pretty much anything with Mindway. Even though you&apos;ve
        customized your homepage while setting up your account, you should
        definitely reorganize it once you get the hang of it and identify the
        most important things you will track.
      </StyledText>
      <StyledText>
        Simply click on your profile icon at the top right of your homepage to
        access the <strong>Settings</strong>, then click{' '}
        <strong>Customize Homepage,</strong> and build a page that brings the
        most value - you can keep on or turn off the widgets you don&apos;t need
        and you can change the order of each of the widgets by holding the bars
        icon and dragging it to your preferred position.
      </StyledText>
      <StyledText>
        Here in the settings you can also set up or change the time for your
        daily logs in the “Reminders” section and you can also manage your Email
        notifications, medication and journaling reminders in the{' '}
        <strong>App Preferences → Notifications</strong> by turning them on or
        off.
      </StyledText>
      <ImageContainer>
        <ImageComp src={HomePageIcon} />
        <ImageComp src={HomePageIcon2} />
      </ImageContainer>
      <Heading>2. What&apos;s a factor?</Heading>
      <StyledText>
        For every little thing or detail you can track, we use one simple word
        that connects everything - Factor. This could mean a morning ritual, a
        meetup with friends, a party, today&apos;s weather, places you’ve
        visited, hobbies, the food you ate, or things you did before & during
        your sleep. Whatever you can think of. Anything. You can even add a
        photo, icon, or emoji to the factor making it more personal and fun.
        Your most commonly logged factors appear on the homepage and less
        frequent ones can be found if you open the widget.
      </StyledText>

      <StyledText>
        The Factors can be fully customized. In the beginning, you will see our
        predefined factors but you can always add new custom ones or turn off
        the ones you see right now. Simply click the expand button (the arrows
        icon) to open the Factors widget, then click the Edit Factors button or
        the small blue pen edit icon to make it personal: add any new Factor you
        can think of, edit Factors&apos; categories, create new customized
        Factors&apos; categories yourself, or manage them by turning them on or
        off. Once created or turned on, you can always find your custom Factors
        in this section on your homepage and select them with one simple click.
        The nutrition and sleep factors work exactly the same way.
      </StyledText>
      <ItalicText>
        <ItalicBold>Tip:</ItalicBold>Tracking your daily factors, such as your
        activities, hobbies, places you&apos;ve visited, or any other factors,
        will allow you to get the correlations and see how different things
        affect your health & wellbeing.
      </ItalicText>
      <ImageContainer>
        <ImageComp src={FactorIcon} />
        <ImageComp src={FactorIcon2} />
      </ImageContainer>

      <Heading>
        3. Unique symptoms, medications & supplements for every health condition
      </Heading>
      <StyledText>
        We understand that everyone is different and has their own needs.
        That&apos;s why you can track{' '}
        <TextBold>Symptoms, Medications & Supplements</TextBold> that suit your
        health goals the best. Health is always a priority, therefore, we
        recommend spending some extra minutes customizing these sections at the
        start.
      </StyledText>
      <SubHeading>3.1. Creating your symptoms</SubHeading>
      <StyledText>
        To edit your Symptoms, click the expand button (the arrows icon) in this
        section and press the edit icon. Symptoms are by default added to
        Mental, Physical, and Reproductive categories, but you can always create
        your own categories (Add Group) or turn on/off the existing ones. You
        can also enter each of the categories to see what symptoms they have
        inside and you can add your specific symptoms (Add Symptom) or turn
        on/off the existing ones. Once you&apos;re done with that, you&apos;ll
        be able to rate your symptoms&apos; severity from your homepage with
        just a couple of clicks. clicks.
      </StyledText>
      <ItalicText>
        <ItalicBold>Tip:</ItalicBold>
        Don&apos;t forget, for the days that you don&apos;t experience a
        symptom, mark its severity as 0 to create more accurate insights.
      </ItalicText>

      <ImageContainer>
        <ImageComp src={SymptomsIcon} />
        <ImageComp src={SymptomsIcon2} />
      </ImageContainer>
      <SubHeading>3.2. Managing your medications & supplements</SubHeading>
      <StyledText>
        To start tracking, add new medications by clicking on the box with a +
        icon inside (or the arrows icon to open the widget) and then click the
        Add Medications button - here you&apos;ll need to specify all the
        information about your medication, such as the name, strength, units of
        measurement, and set the frequency. After selecting the frequency,
        you&apos;ll be able to schedule your medications by clicking the Add
        doses and then setting the time and the dose of a certain medication and
        Mindway will send you notifications for every dose so you wouldn&apos;t
        miss one. Once you set it up, you can log your doses straight from the
        homepage. If you forgot to mark your dose on time, you can do it later
        and specify the exact time you took it or select Skip dose if you
        didn&apos;t drink it.
      </StyledText>
      <ItalicText>
        <ItalicBold>Tip: </ItalicBold>
        Don&apos;t forget, a missed dose is equally important! Mark every taken
        or missed dose to create more accurate insights.
      </ItalicText>
      <ImageContainer>
        <ImageComp src={SymptomsIcon3} />
        <ImageComp src={SymptomsIcon4} />
      </ImageContainer>
      <Heading>
        4. Analyze what impacts your health on the insights page
      </Heading>
      <StyledText>
        To access your insights and statistics, you can click on the statistics
        icon on your homepage at the top right of each of the widgets or you can
        simply find them in the Insights screen where you can select which
        things and for which period you want to see. Here you will be able to
        see your average ratings, counts, trends and changes between different
        periods, correlations, see your medication reports, or what effect it
        had on your symptoms, and see how different factors affect your
        symptoms, mood, sleep, or anything else you wish to analyze.
      </StyledText>
      <MarginBottom>
        <ItalicText>
          <ItalicBold>Tip:</ItalicBold>
          To start seeing Effects you must have 3 days with a factor, for
          example, &quot;Gym&quot;, and 3 days when that factor did not occur.
        </ItalicText>
      </MarginBottom>

      <StyledText>
        Using the Mindway app for a week consistently is sure to give you
        actionable Insights. Let&apos;s say you want to find out how to improve
        your mood. For example, choose &apos;Mood&apos; from the dropdown menu
        at the top and discover a snapshot of what your mood was like recently.
        Take a look at the &quot;Effect on Mood&quot; widget and see that
        &quot;Work from home&quot; and &quot;Busy day&quot; had the best effect.
        Try to have more days like these next week and see if it works!
      </StyledText>
      <ItalicText>
        <ItalicBold>Tip:</ItalicBold>
        Remember - the more days you&apos;ll track, the more accurate insights
        will be.
      </ItalicText>
      <ImageContainer>
        <ImageComp src={AnalyzeIcon} />
        <ImageComp src={AnalyzeIcon2} />
      </ImageContainer>
      <Heading>
        5. Keep all data in one place! Sync with Google Fit or Apple Health
      </Heading>
      <StyledText>
        By syncing Mindway with your phone&apos;s health app, you can
        automatically track your Steps, Sleep, Weight, Heart Rate, Blood
        Pressure, Blood Glucose, and Water intake. If you haven&apos;t already
        synced, simply <ItalicBold>click</ItalicBold> on your{' '}
        <ItalicBold>profile icon</ItalicBold> at the top right of your homepage
        to reach{' '}
        <ItalicBold>
          Settings → App preferences → Connected Health Apps
        </ItalicBold>{' '}
        and turn on <ItalicBold>syncing.</ItalicBold>
      </StyledText>
      <StyledText>
        Keep in mind that syncing is not the only option. You can always make
        manual entries of Health Measurements by yourself.
      </StyledText>
      <MarginBottom>
        <ItalicText>
          <ItalicBold>Tip: </ItalicBold> You can even log multiple health
          metrics entries throughout the day, for example, your naps.
        </ItalicText>
      </MarginBottom>
      <ImageContainer>
        <ImageCompFullWidth src={AnalyzeIcon6} />
      </ImageContainer>
      <StyledText>
        Still have questions about Mindway? Let us know how we can help you.
        Reach out to us on FB, IG, or just write us an email at{' '}
        <EmailLink href="mailto:hello@mindway.app">hello@mindway.app</EmailLink>
        .
      </StyledText>
    </Container>
  </Layout>
);

export default Single;

const EmailLink = styled.a`
  color: ${({ theme }) => theme.colors.secondary} !important;
  text-decoration: underline !important;
  font-weight: 400;
  cursor: pointer;
`;

const ImageCompFullWidth = styled.img`
  background-size: cover;
  border-radius: 12px;
  height: 280px;
  object-fit: cover;
  width: 100%;
  @media ${tablet} {
    // width: 44%;
  }
`;
const ImageComp = styled.img`
  background-size: cover;
  border-radius: 12px;
  height: 280px;
  object-fit: cover;
  width: 45%;
  @media ${tablet} {
    // width: 44%;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 24px 0;
  gap: 1.5rem;
  justify-content: space-between;
`;

const Container = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 3rem 2rem;
  width: 850px;
  @media ${tablet} {
    padding: 3rem 1rem;
  }
`;

const Title = styled(Text).attrs({
  color: 'secondary',
})`
  margin-bottom: 24px;
  text-transform: capitalize;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -0.036em;
  line-height: 140%;
  font-family: Inter;
  @media ${tablet} {
    font-size: 2rem;
  }
`;

const SubHeading = styled(Text).attrs({
  color: 'secondary',
})`
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.036em;
  line-height: 140%;
  margin: 26px 0 22px;
  font-family: Inter;
`;

const Heading = styled(Text).attrs({
  color: 'secondary',
})`
  font-size: 28px;
  font-weight: 600;
  margin: 26px 0 22px;
  letter-spacing: -0.036em;
  line-height: 140%;
  font-family: Inter;
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const StyledText = styled(Text)`
  margin-bottom: 22px;
  font-family: Inter;
  font-weight: 300;
`;

const ItalicText = styled(StyledText)`
  margin-bottom: 22px;

  display: flex;
  font-style: italic;
  display: inline;
`;

const TextBold = styled(StyledText)`
  font-weight: 500;
  display: inline;
`;

const ItalicBold = styled(ItalicText)`
  margin-bottom: 22px;

  font-weight: 500;
`;

const MarginBottom = styled.div`
  margin-bottom: 22px;
`;
